import React, {useCallback, useEffect, useMemo, useState} from "react"
import {useSelector} from "react-redux"
import {
  SingleChoiceQuestion,
  MultipleChoiceQuestion,
  DnDQuestion,
  ParsonsQuestion
} from "@knowledge-pillars-education-inc/kp-fe-lib"
import BackdropLoad from "components/BackdropLoad"
import {selectTaskDetails} from "store/selectors"
import {WorkspacePropsType} from "pages/PracticeExamWindow/types.t"
import {SphereEngineWorkspace} from "./SphereEngineWorkspace"
import {Box} from "@mui/material"

export default function Workspace({
  setDifferentTypeAnswers,
  setKpCodeAnswer,
  setRunDisabled,
  taskAnswer,
  codeEditorData,
  setCodeEditorData,
  onNextClick,
  onRefresh,
  submitLoading,
  tasksAmount,
  currentTaskNumber,
  nextDisabled
}: WorkspacePropsType) {
  const question = useSelector(selectTaskDetails)
  const {
    type, answers, questions, description,
    displayName, multipleDetails
  } = question

  const [taskAnswers, setTaskAnswers] = useState(null)

  const codeString = useMemo(() => {
    if (multipleDetails?.codeSnippet) {
      return multipleDetails?.codeSnippet["code"]
    }

    return ""
  }, [multipleDetails])

  const options = useMemo(() => {
    return multipleDetails?.options || []
  }, [multipleDetails])

  const handleSimpleChange = useCallback(result => {
    const answers = result.reduce((acc, el) => {
      if (el.checked) {
        acc.push({id: el.id})
      }

      return acc
    }, [])

    setDifferentTypeAnswers(answers?.length ? answers : null)
  }, [])

  const handleDndChange = useCallback(dndAnswers => {
    let result = dndAnswers

    if (dndAnswers) {
      result = dndAnswers.map((el) => ({id: el.id, position: el.position}))
    }

    setDifferentTypeAnswers(result)
  }, [])

  const handleParsonsChange = useCallback(code => {
    let result = code

    if (code) {
      result = code.map((el) => ({id: el.id, position: el.position}))
    }

    setDifferentTypeAnswers(result)
  }, [])

  const DnDQuestionMemoized = useMemo(() => {
    return (
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "100%",
          padding: "20px 20px 40px",
          overflowY: "auto",
          background: "white"
        }}
      >
        <DnDQuestion
          questionLines={questions}
          answerLines={answers}
          selectedAnswers={taskAnswers || []}
          description={description}
          displayName={displayName}
          changesHandler={handleDndChange}
        />
      </Box>
    )
  }, [
    questions,
    answers,
    description,
    displayName,
    taskAnswers
  ])

  const ParsonsQuestionMemoized = useMemo(() => {
    return (
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "100%",
          padding: "20px 20px 40px",
          overflowY: "auto",
          background: "white"
        }}
      >
        <Box mx="auto" maxWidth="720px">
          <ParsonsQuestion
            questionLines={answers}
            description={description}
            selectedAnswers={taskAnswers || []}
            displayName={displayName}
            language="javascript"
            handleCodeChanges={handleParsonsChange}
          />
        </Box>
      </Box>
    )
  }, [
    answers,
    description,
    displayName,
    multipleDetails,
    codeString,
    taskAnswers
  ])

  useEffect(() => {
    setTaskAnswers((prevValue) => taskAnswer?.taskId === question?.id ? taskAnswer?.answers : prevValue)
  }, [taskAnswer, question])

  return (
    <>
      {type === "match" ? (
        DnDQuestionMemoized
      ) : type === "practical" ? (
        <SphereEngineWorkspace
          question={question}
          setKpCodeAnswer={setKpCodeAnswer}
          setRunDisabled={setRunDisabled}
          codeEditorData={codeEditorData}
          setCodeEditorData={setCodeEditorData}
          onNextClick={onNextClick}
          onRefresh={onRefresh}
          submitLoading={submitLoading || nextDisabled}
          tasksAmount={tasksAmount}
          currentTaskNumber={currentTaskNumber}
        />
      ) : type === "single" ? (
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "100%",
            padding: "20px 20px 40px",
            overflowY: "auto",
            background: "white"
          }}
        >
          <Box mx="auto" maxWidth="720px">
            <SingleChoiceQuestion
              onChange={handleSimpleChange}
              loading={false}
              codeString={codeString}
              options={options}
              selectedOptions={taskAnswers}
              displayName={displayName}
              language={multipleDetails?.codeSnippet?.language}
              description={description}
            />
          </Box>
        </Box>
      ) : type === "multiple" ? (
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "100%",
            padding: "20px 20px 40px",
            overflowY: "auto",
            background: "white"
          }}
        >
          <Box mx="auto" maxWidth="720px">
            <MultipleChoiceQuestion
              onChange={handleSimpleChange}
              loading={false}
              codeString={codeString}
              options={options}
              selectedOptions={taskAnswers}
              displayName={displayName}
              language={multipleDetails?.codeSnippet?.language}
              description={description}
            />
          </Box>
        </Box>
      ) : type === "parsons" ? (
        ParsonsQuestionMemoized
      ) : (
        <BackdropLoad open/>
      )}
    </>
  )
}
