import React, {useMemo} from "react"
import {Box, Card, CardContent, Grid, Link, Typography} from "@mui/material"
import {VouchersListType} from "types/services/vouchers.service.t"
import ExamItem from "./components/ExamItem"
import AddExamItem from "./components/AddExamItem"
import BackdropLoad from "components/BackdropLoad"
import {useSelector} from "store"
import WPLabIcon from "assets/icons/WPLab"
import {LINKS} from "consts/links"
import {Link as RouterLink} from "react-router-dom"
import Button from "@mui/material/Button"

export type ExamsListCardItem = {
  id: string
  type: "exam" | "lab"
  title: string
  subtitle: string
  examCode: string
  expired: boolean
  expirationDate: string
}

interface Props {
  examsList: VouchersListType
  onActivate: (item: ExamsListCardItem) => void
  onStart: (item: ExamsListCardItem) => void
}

export default function ExamsList({
  examsList,
  onActivate,
  onStart
}: Props) {
  const loading = useSelector((state) => state.practiceVouchers.loading)
  const user = useSelector((store) => store.userSlice)

  const wpExams = useMemo(() => {
    return (examsList || []).filter(i => ["wce", "wws", "wes", "wca"].includes(i.examCode))
  }, [examsList])

  const wpLabs = useMemo(() => {
    return user.isLabLicenseActive ? user.labsLicense!.examCodes.map(i => ({
      ...user.labsLicense,
      examCode: i,
      expired: new Date(user.labsLicense.expirationDate) <= new Date()
    })) : []
  }, [user])

  const list = useMemo(() => {
    const result: ExamsListCardItem[] = [];

    (examsList || []).forEach(i => {
      result.push({
        id: i.id,
        type: "exam",
        title: i.examCode ? i.examCode.toUpperCase() : "Exam",
        subtitle: i.examName,
        examCode: i.examCode,
        expired: i.expired,
        expirationDate: i.expirationDate
      })
    })

    if (!wpExams.length && wpLabs.length) {
      wpLabs.forEach(i => {
        result.push({
          id: i._id,
          type: "lab",
          title: i.examCode,
          subtitle: "WP Lab",
          examCode: i.examCode,
          expired: i.expired,
          expirationDate: i.expirationDate
        })
      })
    }

    return result
  }, [examsList, wpExams, wpLabs])

  return (
    <Grid container item spacing={3} xs={12} mt={0}>
      <BackdropLoad open={loading}/>
      {user.isLabLicenseActive && (
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{height: "100%"}}>
            <CardContent sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 3
            }}>
              <Typography
                variant="h6"
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}>
                WordPress Lab
              </Typography>
              <Box width="40%">
                <WPLabIcon/>
              </Box>
              <Box>
                <Link to={LINKS.wpLab} component={RouterLink} underline="none">
                  <Button variant="contained" color="success">
                    {user.totalTimeSpentInLabs ? "Resume Access" : "Start Lab"}
                  </Button>
                </Link>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      )}
      {list?.length ? (
        list.map(i => (
          <ExamItem
            key={i.id}
            data={i}
            onActivate={onActivate}
            onStart={onStart}
          />
        ))
      ) : (
        <AddExamItem/>
      )}
    </Grid>
  )
}
