import {gql} from "@apollo/client"

export default gql`
  query GetMyInvitations($createdAfter: DateTimeISO) {
    me {
      invitations (targetEntity: classroom, createdAfter: $createdAfter) {
        _id
        createdAt
        status
      }
    }
  }
`
