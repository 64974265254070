import {gql} from "@apollo/client"

export default gql`
  query GetTestingSessions(
    $districtId: GraphQLObjectId
    $schoolId: GraphQLObjectId
    $usersFromClassroomId: GraphQLObjectId
    $userId: GraphQLObjectId
    $take: Int = 100
    $offset: Int = 0
    $sortBy: String
    $order: SortOrder
    $examCodes: [String!]
    $dateStart: DateTimeISO
    $dateEnd: DateTimeISO
    $showResult: String
    $showCanceled: Boolean
    $isPassed: Boolean
  ) {
    testingSessions(
      districtId: $districtId
      schoolId: $schoolId
      usersFromClassroomId: $usersFromClassroomId
      userId: $userId
      take: $take
      offset: $offset
      sortBy: $sortBy
      order: $order
      examCodes: $examCodes
      dateStart: $dateStart
      dateEnd: $dateEnd
      showResult: $showResult
      showCanceled: $showCanceled
      isPassed: $isPassed
    ) {
      hasMore
      total
      items {
        _id
        _score
        createdAt
        examCode
        startDate
        updatedAt
        finishDate
        options {
          objectives
          disableAi
          showResult
        }
        result {
          isPassed
          objectives {
            objectiveCode
            score
            title
            objectiveId
          }
        }
        status
        user {
          _id
          firstName
          lastName
        }
      }
    }
  }
`
