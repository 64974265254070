import {useLazyQuery, useMutation} from "@apollo/client"
import {
  Box,
  Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField,
  Typography
} from "@mui/material"
import RESPOND_DISABILITY_MULTIPLIER_REQUESTS from "api/apollo/mutations/RESPOND_DISABILITY_MULTIPLIER_REQUESTS"
import GET_DISABILITY_MULTIPLIER_REQUESTS from "api/apollo/queries/GET_DISABILITY_MULTIPLIER_REQUESTS"
import DataTable from "components/DataTable"
import {DataTableQueryUpdate, DataTableSchema} from "components/DataTable/types.t"
import {useFormik} from "formik"
import {
  DisabilityMultiplierRequest,
  DisabilityMultiplierRequestStatus,
  GetDisabilityMultiplierRequestsQuery,
  GetDisabilityMultiplierRequestsQueryVariables,
  RespondDisabilityMultiplierRequestsMutation,
  RespondDisabilityMultiplierRequestsMutationVariables
} from "generated/graphql"
import JsFileDownloader from "js-file-downloader"
import React, {useMemo, useState} from "react"
import {useDispatch} from "store"
import {handleError, notifyUser} from "store/slices/notifier/notifier"
import {QueryDataType} from "types/typeUtils"
import convertSecondsToTime from "utils/convertSecondToTime"
import * as Yup from "yup"

interface Props {
  onAfterSubmit: () => void
}

const SESSION_DURATION = 2700

const options = [
  {
    label: "Accept",
    value: "accept"
  },
  {
    label: "Reject",
    value: "reject"
  }
]

export default function ViewRequestsForm({
  onAfterSubmit
}: Props) {
  const dispatch = useDispatch()

  const [requestsData, setRequestsData] = useState<
    QueryDataType<DeepPartial<DisabilityMultiplierRequest>>
  >(null)
  const [selectedRequests, setSelectedRequests] = useState<string[]>([])

  const [disabilityMultiplierRequestsQueryFetch, disabilityMultiplierRequestsQuery] = useLazyQuery<
    GetDisabilityMultiplierRequestsQuery,
    GetDisabilityMultiplierRequestsQueryVariables
  >(GET_DISABILITY_MULTIPLIER_REQUESTS)

  const [respondDisabilityMultiplierRequests, {loading}] = useMutation<
    RespondDisabilityMultiplierRequestsMutation,
    RespondDisabilityMultiplierRequestsMutationVariables
  >(RESPOND_DISABILITY_MULTIPLIER_REQUESTS)

  const requestsError = useMemo(() => {
    return !requestsData && (
      disabilityMultiplierRequestsQuery.error ||
      null
    )
  }, [
    requestsData,
    disabilityMultiplierRequestsQuery.error
  ])

  const formik = useFormik({
    initialValues: {
      rejectionReason: "",
      approved: ""
    },
    validationSchema: Yup.object().shape({
      approved: Yup.string().required("Decision is required"),
      rejectionReason: Yup.string().when("approved", (approved: string) => {
        if (approved === "accept") {
          return Yup.string()
        }
        return Yup.string().required("Reason is required")
      })
    }),
    onSubmit: values => {
      if (!selectedRequests.length) {
        dispatch(notifyUser({
          message: "Choose request first, please",
          variant: "info"
        }))

        return
      }

      respondDisabilityMultiplierRequests({
        variables: {
          rejectionReason: values.rejectionReason,
          approved: values.approved === "accept",
          disabilityMultiplierRequestIds: selectedRequests
        }
      }).then(() => {
        onAfterSubmit()
      }).catch(err => {
        dispatch(handleError(err))
      })
    }
  })

  const tableSchema: DataTableSchema<DisabilityMultiplierRequest> = useMemo(() => {
    return [
      {
        type: "checkbox",
        key: data => data._id,
        selectAll: true
      },
      {
        type: "text",
        headerText: "Full Name",
        fieldName: "targetUser.fullName",
        headerNoWrap: true,
        contentWrap: "nowrap"
      },
      {
        type: "text",
        headerText: "District",
        fieldName: "district.name",
        headerNoWrap: true,
        contentWrap: "nowrap"
      },
      {
        type: "text",
        headerText: "School",
        fieldName: "school.name",
        headerNoWrap: true,
        contentWrap: "nowrap"
      },
      {
        type: "custom",
        headerText: "Extended time",
        headerNoWrap: true,
        contentWrap: "nowrap",
        content: data => {
          return data.multiplierValue ? convertSecondsToTime(SESSION_DURATION * data.multiplierValue) : ""
        }
      },
      {
        type: "text",
        headerText: "Reason",
        headerNoWrap: true,
        fieldName: "message"
      },
      {
        type: "custom",
        headerText: "Document",
        headerNoWrap: true,
        contentWrap: "nowrap",
        content: data => {
          const filename = data?.proofFileMetadata?.filename
          const url = data.proofFile
          return url && filename ? (
            <Button variant="text" onClick={() => {
              return new JsFileDownloader({
                url,
                filename
              })
            }}>
              {filename}
            </Button>
          ) : ""
        }
      },
      {
        type: "text",
        headerText: "Requested by",
        headerNoWrap: true,
        contentWrap: "nowrap",
        fieldName: "createdByUser.fullName"
      },
      {
        type: "text",
        headerText: "Status",
        headerNoWrap: true,
        contentWrap: "nowrap",
        fieldName: "status"
      }
    ]
  }, [requestsData, selectedRequests])

  const handleSelect = (selected) => {
    setSelectedRequests(selected || [])
  }

  const handleQuery: DataTableQueryUpdate<{
    refetch?: boolean
  }> = (state) => {
    const {itemsPerPage, page, sort, searchQuery} = state

    const commonVars = {
      take: itemsPerPage,
      offset: itemsPerPage * page,
      sortBy: sort?.key,
      order: sort?.order,
      search: searchQuery,
      status: [DisabilityMultiplierRequestStatus.Pending]
    }

    disabilityMultiplierRequestsQueryFetch({
      variables: commonVars,
      fetchPolicy: "network-only"
    }).then(res => {
      setRequestsData(res.data?.disabilityMultiplierRequests || null)
    })
  }

  return (
    <Box p={4} minWidth="54vw">
      <form onSubmit={formik.handleSubmit}>
        <Typography variant="h5" textAlign="center" mb={2}>
          Requests
        </Typography>
        <DataTable
          schema={tableSchema}
          data={requestsData?.items}
          error={!!requestsError}
          itemsTotalCount={requestsData?.total}
          onQueryUpdate={handleQuery}
          onSelectionUpdate={handleSelect}
          search="Search Requests"
          loading={disabilityMultiplierRequestsQuery.loading}
          lastPage={!requestsData?.hasMore}
        />
        <Box my={2}>
          <Grid container spacing={3} direction="row">
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel error={!!(formik.touched.approved && formik.errors.approved)}>
                  Decision
                </InputLabel>
                <Select
                  name="approved"
                  onBlur={formik.handleBlur}
                  label="Select"
                  error={!!(formik.touched.approved && formik.errors.approved)}
                  value={formik.values.approved}
                  onChange={e => {
                    formik.handleChange(e)
                    if (e.target.value === "accept") {
                      formik.setFieldValue("rejectionReason", "")
                      formik.setErrors({})
                      formik.setTouched({}, false)
                    }
                  }}>
                  {options.map(i => (
                    <MenuItem key={i.value} value={i.value}>
                      {i.label}
                    </MenuItem>
                  ))}
                </Select>
                {Boolean(formik.touched.approved && formik.errors.approved) && (
                  <FormHelperText
                    error
                    sx={{
                      ml: "14px",
                      mr: "14px"
                    }}>
                    {formik.touched.approved && formik.errors.approved}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="rejectionReason"
                disabled={formik.values.approved === "accept"}
                value={formik.values.rejectionReason}
                error={!!(formik.touched.rejectionReason && formik.errors.rejectionReason)}
                helperText={formik.touched.rejectionReason && formik.errors.rejectionReason}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                label="Reason"
                fullWidth
                variant="outlined"
                multiline
              />
            </Grid>
          </Grid>
        </Box>
        <Box display="flex" justifyContent="end">
          <Button
            type="submit"
            variant="contained"
            disabled={!formik.dirty || loading}
          >
            Submit
          </Button>
        </Box>
      </form>
    </Box>
)
}
