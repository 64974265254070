import {gql} from "@apollo/client"

export default gql`
  mutation ExecuteBatchImportUsers(
    $schoolId: GraphQLObjectId!,
    $users: [UserInput!]!,
    $classroomId: GraphQLObjectId
  ) {
    executeBatchImportUsers(
      schoolId: $schoolId,
      users: $users,
      classroomId: $classroomId
    ) {
      errors {
        email
        error
      }
      actions {
        email
        action
      }
    }
  }
`
