import {TestingGroupPaymentType} from "generated/graphql"

const names = {
  [TestingGroupPaymentType.LicenseInventory]: "License inventory",
  [TestingGroupPaymentType.StudentInventory]: "Student inventory"
}

export default function getTestingGroupPaymentTypeName(input: string) {
  return names[input]
}
