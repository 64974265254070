import {gql} from "@apollo/client"

export default gql`
  query GetLabSessions(
    $districtId: GraphQLObjectId
    $schoolId: GraphQLObjectId
    $usersFromClassroomId: GraphQLObjectId
    $userId: GraphQLObjectId
    $onlyLatest: Boolean
    $isConnection: Boolean
    $search: String
    $sortBy: String
    $order: SortOrder
    $offset: Int = 0
    $take: Int = 100
  ) {
    labSessions(
      districtId: $districtId
      schoolId: $schoolId
      usersFromClassroomId: $usersFromClassroomId
      createdByUserId: $userId
      onlyLatest: $onlyLatest
      isConnection: $isConnection
      search: $search
      sortBy: $sortBy
      order: $order
      offset: $offset
      take: $take
    ) {
      hasMore
      total
      items {
        _id
        createdAt
        updatedAt
        createdByUserId
        createdByUser {
          _id
          firstName
          lastName
          fullName
          email
          totalTimeSpentInLabs
        }
        instanceId
        schoolId
        districtId
        finishedAt
        expirationDate
        isConnection
        isExpired
        isFinished
        instance {
          _id
          status
        }
        progressChannel
        progressOfUserId
        progressOfUser {
          _id
          firstName
          lastName
          fullName
          email
          totalTimeSpentInLabs
        }
        isInstanceReady
      }
    }
  }
`
