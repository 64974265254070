import {gql} from "@apollo/client"

export default gql`
  mutation AddStaffToSchool(
    $isAdmin: Boolean!,
    $isTeacher: Boolean!,
    $schoolId: GraphQLObjectId!,
    $userEmail: String!
  ) {
    addStaffToSchool(
      isAdmin: $isAdmin,
      isTeacher: $isTeacher,
      schoolId: $schoolId,
      userEmail: $userEmail
    ) {
      _id
    }
  }
`
