import {useLazyQuery} from "@apollo/client"
import GET_USER_GENERAL_INFO from "api/apollo/queries/GET_USER_GENERAL_INFO"
import EnrollmentsTable from "components/EnrollmentsTable"
import ExtendedSessionTimeTable from "components/ExtendedSessionTimeTable"
import LabSessionsTable from "components/LabSessionsTable"
import LayoutWithBreadcrumbs from "components/LayoutWithBreadcrumbs"
import SessionsTable from "components/SessionsTable"
import UpdateUserForm from "./components/UpdateUserForm"
import UpdateUserPassword from "components/UpdateUserPassword"
import {LINKS} from "consts/links"
import {
  GetUserGeneralInfoQuery,
  GetUserGeneralInfoQueryVariables,
  User
} from "generated/graphql"
import {capitalize} from "lodash"
import AccessLevels from "./components/AccessLevels"
import React, {useEffect, useMemo, useState} from "react"
import {
  Box,
  CircularProgress,
  Divider,
  Paper,
  Tab,
  Tabs,
  Typography
} from "@mui/material"
import {useParams, useSearchParams} from "react-router-dom"
import {useSelector} from "store"

type ContentTypes = "admin" | "district" | "school"
type UserType = "teacher" | "student" | "user"

interface Props {
  breadcrumbs: {text: string, path?: string}[]
  type: ContentTypes
  userType?: UserType
}

export default function UserDetails({
  type,
  userType = "user",
  breadcrumbs
}: Props) {
  const params = useParams()
  const [searchParams] = useSearchParams()

  const districtId = useSelector((store) => store.districtSlice.currentDistrict)

  const tabs = useMemo(() => {
    return [
      {
        label: "General",
        value: "general"
      },
      userType === "teacher" && type !== "school" && {
        label: "Access Levels",
        value: "access-levels"
      },
      {
        label: "Exam Results",
        value: "exam-results"
      },
      {
        label: "Practice Tests",
        value: "practice-tests"
      },
      {
        label: "Labs",
        value: "labs"
      },
      {
        label: "Extended Time",
        value: "extended-time"
      },
      userType !== "teacher" && {
        label: "Security",
        value: "security"
      }
    ].filter(Boolean)
  }, [userType, type])

  const tabFromParams = useMemo(() => {
    const param = searchParams.get("tab")

    if (param && tabs.map(i => i.value).includes(param)) {
      return param
    } else {
      return "general"
    }
  }, [searchParams, tabs])

  const [currentTab, setCurrentTab] = useState(tabFromParams)
  const [userData, setUserData] = useState<
    Partial<User> | null
  >(null)

  const [userQuery, {loading}] = useLazyQuery<
    GetUserGeneralInfoQuery,
    GetUserGeneralInfoQueryVariables
  >(GET_USER_GENERAL_INFO)

  const title = useMemo(() => {
    return loading ? "Loading..." : (userData?.fullName || "")
  }, [loading, userData])

  const pageTitle =  useMemo(() => {
    const currentUserType = capitalize(userType)
    return loading ? "Loading..." : (
      (userData?.fullName ? `${currentUserType} - ${userData.fullName}` : currentUserType)
    )
  }, [userData, loading, userType])

  useEffect(() => {
    if (((type === "district" && districtId) || type !== "district") && params.id) {
      handleQuery(params.id)
    }
  }, [params.id, districtId, type])

  const handleQuery = (id: string) => {
    userQuery({
      variables: {
        userId: id
      }
    }).then(res => {
      setUserData(res.data?.getUser || null)
    })
  }

  return (
    <LayoutWithBreadcrumbs
      helmetTitle={pageTitle}
      title={pageTitle}
      breadcrumbs={[...breadcrumbs.map(i => {
        return i
      }), {
        text: title
      }]}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-end"
        gap="4rem">
        <Tabs
          indicatorColor="primary"
          onChange={(_e, value) => setCurrentTab(value)}
          scrollButtons="auto"
          textColor="primary"
          value={currentTab}
          variant="scrollable">
          {tabs.map(i => (
            <Tab key={i.value} label={i.label} value={i.value}/>
          ))}
        </Tabs>
      </Box>
      <Divider/>
      <Box mt={2}>
        {!userData && loading ? (
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" my={24}>
            <CircularProgress/>
          </Box>
        ) : userData && (
          (currentTab === "security" ? (
            <UpdateUserPassword userId={userData._id}/>
          ) : currentTab === "exam-results" ? (
            <SessionsTable
              type="examIndividual"
              userId={userData._id}
              detailsLink={`${type === "district" ? `${LINKS.districtAdmin}/exam-results` : "/my-results"}/exam-details/`}
            />
          ) : currentTab === "practice-tests" ? (
            <SessionsTable
              userId={userData._id}
              type="testingIndividual"
              detailsLink={`${type === "district" ? LINKS.districtAdmin : ""}/practice-results/exam-details/`}
            />
          ) : currentTab === "access-levels" ? (
            <AccessLevels
              teacher={userData}
              districtId={type === "district" && districtId}
            />
          ) : currentTab === "labs" ? (
            <Box>
              <LabSessionsTable
                type="user"
                userId={userData._id}
                latestOnly
              />
              <Paper variant="outlined" sx={{p: 2, mt: 2}}>
                <Typography color="textPrimary" variant="h6">
                  Previous saved sessions
                </Typography>
                <Box mt={2}>
                  <LabSessionsTable
                    type="user"
                    userId={userData._id}
                    prevOnly
                    showFinishDate
                  />
                </Box>
              </Paper>
            </Box>
          ) : currentTab === "extended-time" ? (
            <ExtendedSessionTimeTable userId={userData._id}/>
          ) : (
            <Box>
              <Paper variant="outlined" sx={{p: 2, mb: 4}}>
                <Typography color="textPrimary" variant="h6">
                  Personal Details
                </Typography>
                <Box mb={2}>
                  <UpdateUserForm
                    isTeacher={userType === "teacher"}
                    user={userData}
                    onAfterSubmit={handleQuery}
                  />
                </Box>
              </Paper>
              <Paper variant="outlined" sx={{p: 2}}>
                <Typography color="textPrimary" variant="h6">
                  Enrollments
                </Typography>
                <Box mt={2}>
                  <EnrollmentsTable
                    userData={userData}
                    isAdminView={type === "admin"}
                    districtId={type === "district" && districtId}
                  />
                </Box>
              </Paper>
            </Box>
          ))
        )}
      </Box>
    </LayoutWithBreadcrumbs>
  )
}
