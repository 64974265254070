import {LINKS} from "consts/links"
import React, {useEffect, useState} from "react"
import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  Switch,
  Typography
} from "@mui/material"
import {useDispatch} from "react-redux"
import {useNavigate} from "react-router"
import {getAllVouchers, setCurrentVoucher} from "store/slices/practiceVouchers/practiceVouchers"
import {OneExamType} from "types/services/practiceSession.service.t"
import ExamsList, {ExamsListCardItem} from "./components/ExamsList"
import {useSelector} from "store"
import {ExamStartActivateHandlerType} from "./types.t"
import {sortedExamsListSelector} from "store/selectors"

export default function StudentVouchersList() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [showExpired, setShowExpired] = useState(false)

  const sortedExamsList = useSelector((state) => sortedExamsListSelector(state, showExpired))

  useEffect(() => {
    dispatch(getAllVouchers())
  }, [])

  const handleActivate = (item: ExamsListCardItem) => {
    const ref = sortedExamsList.find(i => i.id === item.id)

    // dispatch(setCurrentVoucher(examItem))
    // navigate(`/practice-tests${LINKS.addExamByVoucher}/${examItem.examName}`)

    navigate(`${LINKS.addExamByVoucher}/${ref.examName}`)
  }

  const handleStart = (item: ExamsListCardItem) => {
    if (item.type === "exam") {
      const ref = sortedExamsList.find(i => i.id === item.id)

      dispatch(setCurrentVoucher(ref))
      navigate(`${LINKS.selectExamMode}/${ref.id}-${ref.examCode}`)
    } else {
      navigate(`${LINKS.wpLab}/${item.examCode}`)
    }
  }

  return (
    <Grid item xs={12} mt={3}>
      <Card>
        <CardContent>
          <Grid
            alignItems="center"
            container
            justifyContent="space-between"
            spacing={3}
            item
            xs={12}>
            <Grid item ml="auto">
              <Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showExpired}
                      edge="start"
                      name="direction"
                      onChange={() => setShowExpired(current => !current)}
                    />
                  }
                  label={
                    <Box>
                      Expired Switch
                      <Typography
                        color="textSecondary"
                        component="p"
                        variant="caption">
                        Display expired access
                      </Typography>
                    </Box>
                  }
                />
              </Box>
            </Grid>
          </Grid>
          <ExamsList
            examsList={sortedExamsList}
            onStart={handleStart}
            onActivate={handleActivate}
          />
        </CardContent>
      </Card>
    </Grid>
  )
}
