import {useMutation} from "@apollo/client"
import ARCHIVE_CLASSROOM from "api/apollo/mutations/ARCHIVE_CLASSROOM"
import CREATE_CLASSROOM from "api/apollo/mutations/CREATE_CLASSROOM"
import DELETE_CLASSROOM from "api/apollo/mutations/DELETE_CLASSROOM"
import UPDATE_CLASSROOM_BY_ID from "api/apollo/mutations/UPDATE_CLASSROOM_BY_ID"
import ConfirmationAlert from "components/ConfirmationAlert"
import {xor} from "lodash"
import * as Yup from "yup"
import ExamsTable from "./components/ExamsTable"
import TeachersTable from "./components/TeachersTable"
import {LINKS} from "consts/links"
import {useFormik} from "formik"
import {
  ArchiveClassroomMutation,
  ArchiveClassroomMutationVariables,
  Classroom, CreateClassroomMutation, CreateClassroomMutationVariables,
  DeleteClassroomMutation,
  DeleteClassroomMutationVariables, UpdateClassroomByIdMutation, UpdateClassroomByIdMutationVariables
} from "generated/graphql"
import React, {useState} from "react"
import {Box, Button, Grid, Paper, TextField, Typography} from "@mui/material"
import {useNavigate} from "react-router-dom"
import {handleError, notifyUser} from "store/slices/notifier/notifier"
import {useDispatch, useSelector} from "store"

interface Props {
  classroom?: DeepPartial<Classroom>
  onClassroomUpdate?: () => void
  loading?: boolean
}

export default function ManageClassroomSection({
  classroom,
  onClassroomUpdate,
  loading
}: Props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const schoolId = useSelector((store) => store.schoolSlice.currentSchool)

  const [showArchiveAlert, setShowArchiveAlert] = useState(false)
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const [selectedExams, setSelectedExams] = useState<string[]>(classroom?.defaultExamFilter || [])

  const formik = useFormik({
    initialValues: {
      classroomName: classroom?.name
    },
    validationSchema: Yup.object().shape({
      classroomName: Yup.string().required("Classroom Name is required")
    }),
    onSubmit: ({classroomName}) => {
      if (selectedExams.length) {
        if (classroom) {
          updateClassroom({
            variables: {
              classroomId: classroom._id,
              classroomPayload: {
                name: classroomName,
                defaultExamFilter: selectedExams
              }
            }
          }).then(() => {
            dispatch(notifyUser({message: "UPDATE_CLASSROOM_SUCCESS"}))
            onClassroomUpdate()
          }).catch(err => {
            dispatch(handleError(err))
          })
        } else {
          createClassroom({
            variables: {
              newClassroomData: {
                name: classroomName,
                schoolId
              }
            }
          }).then(res => {
            updateClassroom({
              variables: {
                classroomId: res.data.addClassroom._id,
                classroomPayload: {
                  defaultExamFilter: selectedExams
                }
              }
            }).then(() => {
              dispatch(notifyUser({message: "CREATE_CLASSROOM_SUCCESS"}))
              navigate(LINKS.classrooms)
            })
            // onClassroomUpdate(res.data.addClassroom._id)
          }).catch(err => {
            dispatch(handleError(err))
          })
        }
      } else {
        dispatch(notifyUser({
          message: "Choose program first, please",
          variant: "info"
        }))
      }
    }
  })

  const [createClassroom] = useMutation<
    CreateClassroomMutation,
    CreateClassroomMutationVariables
  >(CREATE_CLASSROOM)

  const [updateClassroom] = useMutation<
    UpdateClassroomByIdMutation,
    UpdateClassroomByIdMutationVariables
  >(UPDATE_CLASSROOM_BY_ID)

  const [archiveClassroom] = useMutation<
    ArchiveClassroomMutation,
    ArchiveClassroomMutationVariables
  >(ARCHIVE_CLASSROOM)

  const [deleteClassroom] = useMutation<
    DeleteClassroomMutation,
    DeleteClassroomMutationVariables
  >(DELETE_CLASSROOM)

  const handleArchive = (action: "ARCHIVE" | "UNARCHIVE") => {
    archiveClassroom({
      variables: {
        archived: action === "ARCHIVE",
        classroomId: classroom._id
      }
    }).then(() => {
      onClassroomUpdate()

      dispatch(notifyUser({
        message: action === "ARCHIVE" ? "Classroom archived" : "Classroom unarchived"
      }))
    }).catch((err) => {
      dispatch(handleError(err))
    })
  }

  const handleDelete = () => {
    deleteClassroom({
      variables: {
        classroomId: classroom._id
      }
    }).then(() => {
      navigate(LINKS.classrooms)
      dispatch(notifyUser({
        message: "Classroom removed"
      }))
    }).catch((err) => {
      dispatch(handleError(err))
    })
  }

  return (
    <Box position="relative">
      <Box my={2}>
        <Paper variant="outlined" sx={{p: 2}}>
          <Typography color="textPrimary" variant="h6" mb={2}>
            Classroom Name
          </Typography>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <form onSubmit={formik.handleSubmit} style={{width: "50%"}}>
              <Grid
                container
                spacing={2}
                width="100%"
                direction="row"
                alignItems="center">
                <Grid item xs={8}>
                  <TextField
                    size="medium"
                    id="classroomName"
                    fullWidth
                    variant="outlined"
                    placeholder="Classroom Name"
                    onChange={formik.handleChange}
                    value={formik.values.classroomName}
                    error={!!(formik.touched.classroomName && formik.errors.classroomName)}
                  />
                </Grid>
              </Grid>
            </form>
          </Box>
        </Paper>
      </Box>
      {classroom &&
        <Box my={2}>
          <Paper variant="outlined" sx={{p: 2}}>
            <Typography color="textPrimary" variant="h6" mb={2}>
              Teachers
            </Typography>
            <TeachersTable classroom={classroom} onClassroomUpdate={onClassroomUpdate} loading={loading}/>
          </Paper>
        </Box>
      }
      <Box my={2}>
        <Paper variant="outlined" sx={{p: 2}}>
          <Typography color="textPrimary" variant="h6" mb={2}>
            Programs for my classroom
          </Typography>
          <ExamsTable
            classroom={classroom}
            loading={loading}
            onSubmit={setSelectedExams}
          />
        </Paper>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="flex-end" gap={2} py={2}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => formik.handleSubmit()}
          disabled={classroom && (
            classroom.name === formik.values.classroomName && (
            !xor(selectedExams, (classroom.defaultExamFilter || [])).length
          ))}>
          Save
        </Button>
      </Box>
      {classroom &&
        <Box my={2}>
          <Paper variant="outlined" sx={{p: 2}}>
            <Typography color="textPrimary" variant="h6" mb={2}>
              Danger zone
            </Typography>
            <Box display="flex" alignItems="center" justifyContent="flex-end" gap={2} py={2}>
              <Button
                variant="outlined"
                size="large"
                color="warning"
                onClick={() => classroom.isArchived ? handleArchive("UNARCHIVE") : setShowArchiveAlert(true)}>
                {classroom.isArchived ? "Unarchive Classroom" : "Archive Classroom"}
              </Button>
              <ConfirmationAlert
                isOpen={showArchiveAlert}
                setOpen={setShowArchiveAlert}
                handleConfirm={() => handleArchive("ARCHIVE")}
                handleCancel={() => setShowArchiveAlert(false)}
                dialogTitle={`Are you sure you want to archive classroom ${classroom.name}?`}
                dialogContentText="This action can be reverted and the classroom can be unarchived if needed."
                cancelText="Cancel"
                confirmText={{color: "warning", text: "Archive"}}
              />
              <Button variant="outlined" size="large" color="error" onClick={() => setShowDeleteAlert(true)}>
                Delete Classroom
              </Button>
              <ConfirmationAlert
                isOpen={showDeleteAlert}
                setOpen={setShowDeleteAlert}
                handleConfirm={handleDelete}
                handleCancel={() => setShowDeleteAlert(false)}
                dialogTitle={`Are you sure you want to delete classroom ${classroom.name}?`}
                dialogContentText="This action can’t be reverted and the classroom will be deleted from your school account. Student data will still exist in the system but students will not be enrolled anymore in this classroom."
                cancelText="Cancel"
                confirmText={{color: "error", text: "Delete"}}
              />
            </Box>
          </Paper>
        </Box>
      }
    </Box>
  )
}
