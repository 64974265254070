import {useMutation, useQuery} from "@apollo/client"
import CancelIcon from "@mui/icons-material/Cancel"
import DoneIcon from "@mui/icons-material/Done"
import {
  Box,
  Button,
  Paper,
  Typography
} from "@mui/material"
import DELETE_JOIN_REQUEST from "api/apollo/mutations/DELETE_JOIN_REQUEST"
import RESPOND_INVITATION from "api/apollo/mutations/RESPOND_INVITATION"
import GET_MY_CLASSROOMS_REQUESTS from "api/apollo/queries/GET_MY_CLASSROOMS_REQUESTS"
import DataTable from "components/DataTable"
import {DataTableSchema} from "components/DataTable/types.t"
import {
  DeleteJoinRequestMutation,
  DeleteJoinRequestMutationVariables,
  GetMyClassroomsRequestsQuery,
  GetMyClassroomsRequestsQueryVariables,
  Invitation,
  InvitationStatus,
  RespondInvitationMutation,
  RespondInvitationMutationVariables,
  WaitingList
} from "generated/graphql"
import React, {useMemo} from "react"
import {useDispatch} from "store"
import {handleError} from "store/slices/notifier/notifier"
import formatDate from "utils/formatDate"
import GET_MY_INVITATIONS from "api/apollo/queries/GET_MY_INVITATIONS"
import getPastDateISO from "utils/getPastDateISO"

interface Props {
  innerTitle?: boolean
  onUpdate?: () => void
}

export default function Requests({
  innerTitle,
  onUpdate
}: Props) {
  const dispatch = useDispatch()

  const requestsQuery = useQuery<
    GetMyClassroomsRequestsQuery,
    GetMyClassroomsRequestsQueryVariables
  >(GET_MY_CLASSROOMS_REQUESTS, {fetchPolicy: "network-only"})

  const [respondInvitation] = useMutation<
    RespondInvitationMutation,
    RespondInvitationMutationVariables
  >(RESPOND_INVITATION, {
    refetchQueries: [{
      query: GET_MY_INVITATIONS,
      variables: {createdAfter: getPastDateISO(7)},
      fetchPolicy: "network-only"
    }]
  })

  const [deleteJoinRequest] = useMutation<
    DeleteJoinRequestMutation,
    DeleteJoinRequestMutationVariables
  >(DELETE_JOIN_REQUEST, {
    refetchQueries: [{
      query: GET_MY_INVITATIONS,
      variables: {createdAfter: getPastDateISO(7)},
      fetchPolicy: "network-only"
    }]
  })

  const requestsData = useMemo(() => {
    return [...(requestsQuery.data?.me?.invitations || []), ...(requestsQuery.data?.me?.waitingList || [])]
  }, [requestsQuery.data])

  const requestsError = useMemo(() => {
    return !requestsQuery.data && (requestsQuery.error || null)
  }, [requestsQuery.data, requestsQuery.error])

  const tableSchema: DataTableSchema<Invitation | WaitingList> = useMemo(() => {
    return [
      {
        type: "text",
        headerText: "School",
        fieldName: "classroom.school.name"
      },
      {
        type: "text",
        headerText: "Classroom",
        fieldName: "classroom.name"
      },
      {
        type: "text",
        headerText: "Teacher",
        fieldName: "createdByUserFullName"
      },
      {
        type: "custom",
        headerText: "Invited On",
        content: data => {
          return "createdAt" in data ? formatDate(data.createdAt) : ""
        }
      },
      {
        type: "custom",
        headerText: "",
        content: data => (
          <>
            {data.__typename === "WaitingList" ? (
              <Button
                color="warning"
                size="small"
                onClick={() => handleCancelRequest(data._id)}
                startIcon={<CancelIcon/>}>
                Cancel request
              </Button>
            ) : (
              <Box>
                <Button
                  color="success"
                  size="small"
                  onClick={() => handleManageInvitation("ACCEPT", data._id)}
                  startIcon={<DoneIcon/>}>
                  Accept
                </Button>
                <Button
                  color="error"
                  size="small"
                  onClick={() => handleManageInvitation("REJECT", data._id)}
                  startIcon={<CancelIcon/>}
                  sx={{ml: 2}}>
                  Decline
                </Button>
              </Box>
            )}
          </>
        )
      }
    ]
  }, [])

  const handleManageInvitation = (action: "ACCEPT" | "REJECT", id: string) => {
    respondInvitation({
      variables: {
        decision: action === "ACCEPT" ? InvitationStatus.Accepted : InvitationStatus.Rejected,
        invitationId: id
      }
    }).then(() => {
      requestsQuery.refetch()
      onUpdate && onUpdate()
    }).catch((err) => {
      dispatch(handleError(err))
    })
  }

  const handleCancelRequest = (id: string) => {
    deleteJoinRequest({
      variables: {
        joinRequestId: id
      }
    }).then(() => {
      requestsQuery.refetch()
      onUpdate && onUpdate()
    }).catch((err) => {
      dispatch(handleError(err))
    })
  }

  return (
    <Box mb={requestsData?.length ? 3 : 0}>
      {!!requestsData?.length && (
        <Box>
          {!innerTitle && (
            <Typography color="textPrimary" variant="h6" mb={2}>
              Pending Requests
            </Typography>
          )}
          <Box>
            <Paper variant="outlined" sx={{p: 2}}>
              {innerTitle && (
                <Typography color="textPrimary" variant="h6" mb={2}>
                  Pending Requests
                </Typography>
              )}
              <DataTable
                schema={tableSchema}
                data={requestsData}
                loading={requestsQuery.loading}
                error={!!requestsError}
              />
            </Paper>
          </Box>
        </Box>
      )}
    </Box>
  )
}
