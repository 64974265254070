export const LINKS = {
  certify: "https://certify.knowledge-pillars.com",
  kpContactUs: "https://knowledge-pillars.com/contact-us/",
  shop: "https://knowledge-pillars.com/shop/",
  main: "/",
  dashboard: "/dashboard",
  signUp: "/sign-up",
  myClassrooms: "/my-classrooms",
  forgotPassword: "/forgot-password",
  updateUserAccount: "/update-user-account",
  examsOnboard: "/user/exams-onboard",
  examsSelection: "/user/exams-selection",
  examsConfirmPrivacy: "/user/exams-confirm-privacy",
  examsPaymentMethod: "/user/exams-payment-method",
  examsSelectGroup: "/user/exams-select-group",
  examsVerify: "/user/exams-verify",
  examsVoucher: "/user/exams-voucher",
  examsInfo: "/user/exams-info",
  examsProctorVerifi: "/user/exams-verify-proctor",
  examWindow: "/user/examination-window",
  faqs: "/faqs",
  privacyPolicy: "/privacy-policy",
  termsAndCond: "/terms-and-conditions",
  results: "/results",
  smeSelectAction: "/sme",
  smeExams: "/sme/exams",
  smeTasksList: "/sme/tasks",
  smeReviewTasks: "/sme/tasks/review",
  smeScoreTasks: "/sme/tasks/score",
  // new tasks routes. Delete old, if ok
  smeScoreTaskItem: "/sme/tasks/score/:examCode/:questionId",
  smeReviewTaskItem: "/sme/tasks/review/:examCode",
  smeDynamicTasksList: "/sme/tasks/list/:selectedOption/:examCode",
  selectExamMode: "/select-exam-mode",
  selectModeOptions: "/select-mode-options",
  addExamByVoucher: "/add-exam-by-voucher",
  validatePaymentVoucher: "/voucher/validate-voucher/?",
  examAnalytics: "/exam-analytics",
  examResults: "/student-results",
  studentPracticeResults: "/student-practice-results",
  schoolPersonnel: "/school-personnel",
  testingGroup: "/testing-groups",
  addTestingGroup: "/add-testing-group",
  addEditTestingGroup: "/add-edit-group",
  studentsListPage: "/students",
  licenseListPage: "/management-license-list",
  labList: "/wp-labs",
  extendedTime: "/extended-time",
  licenseLogs: "/license-logs",
  additionalExamInfoPage: "/additional-exam-info",
  registerAsProctor: "/register-as-proctor",
  startExam: "/start-exam",
  assignPracticeTestsPage: "/assign-practice-tests",
  classrooms: "/classrooms",
  createClassroom: "/create-classroom",
  courses: "/courses",
  settings: "/settings",
  wpLab: "/wordpress-lab",
  wpLabWindow: "/wordpress-lab-window",
  studentWpLabWindow: "/student-wordpress-lab-window",
  districtAdmin: "/district-admin",
  districtAdminSchools: "/district-admin/schools",
  districtAdminExamPassingRate: "/district-admin/exam-passing-rate",
  districtAdminStudents: "/district-admin/students",
  districtAdminPersonnel: "/district-admin/personnel",
  districtAdminLicenseList: "/district-admin/management-license-list",
  districtAdminExamSessions: "/district-admin/exam-sessions",
  districtAdminPracticeSessions: "/district-admin/practice-sessions",
  districtAdminLicenseLogs: "/district-admin/license-logs",
  districtAdminLabList: "/district-admin/wp-labs",
  districtAdminStudentWpLabWindow: "/district-admin/student-wordpress-lab-window",
  districtAdminExtendedTime: "/district-admin/extended-time",
  admin: "/admin",
  adminDistricts: "/admin/districts",
  adminSchools: "/admin/schools",
  adminUsers: "/admin/users",
  adminExamResults: "/admin/exam-results",
  adminExamPassingRate: "/admin/exam-passing-rate",
  adminInventory: "/admin/inventory",
  adminLicenseLogs: "/admin/license-logs",
  adminTasks: "/admin/tasks",
  adminTaskReports: "/admin/task-reports",
  adminExams: "/admin/exams",
  adminPersonnel: "/admin/personnel",
  adminLabList: "/admin/wp-labs",
  adminStudentWpLabWindow: "/admin/student-wordpress-lab-window",
  adminExtendedTime: "/admin/extended-time",
  certificateTemplate: "/_internal/certificate",
  certificate: "/c",
  authentication: "/authentication"
}
