import {ExamsListCardItem} from "../index"
import React from "react"
import formatDate from "utils/formatDate"
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography
} from "@mui/material"
import {makeStyles} from "@mui/styles"

interface Props {
  data: ExamsListCardItem
  onActivate: (item: ExamsListCardItem) => void
  onStart: (item: ExamsListCardItem) => void
}

const useStyles = makeStyles({
  logoImage: {
    width: "98px",
    height: "98px",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.12),0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
    borderRadius: "50%",
    padding: "5px",
    zIndex: 1,
    backgroundColor: "#ffffff",
    objectFit: "contain"
  }
})

export default function ExamItem({
  data,
  onStart,
  onActivate
}: Props) {
  const s = useStyles()

  const handleStartOrActivateExamHandler = () => {
    return data.expired ? onActivate(data) : onStart(data)
  }

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card>
        <CardContent>
          <Grid container direction="column" spacing={4}>
            <Grid
              item
              sx={{
                maxWidth: "100% !important"
              }}>
              <Typography
                variant="h6"
                sx={{
                  textTransform: "uppercase",
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}>
                {data.title}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  wordBreak: "break-all"
                }}>
                {data.subtitle}
              </Typography>
            </Grid>
            <Box
              mt={4}
              sx={{
                marginLeft: "32px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                "&:after": {
                  content: "\" \"",
                  position: "absolute",
                  top: "50%",
                  left: 0,
                  transform: "translateY(-50%)",
                  height: "1px",
                  width: "100%",
                  backgroundColor: "#ffffff",
                  zIndex: 0,
                  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.12),0px 0px 0px 1px rgba(0, 0, 0, 0.05)"
                }
              }}>
              <img
                src={`/examIcons/${data.examCode}.png`}
                alt=""
                className={s.logoImage}
              />
            </Box>
            <Grid item sm={2}>
              <Button
                onClick={handleStartOrActivateExamHandler}
                variant={data.expired ? "outlined" : "contained"}
                color="primary"
                fullWidth
                sx={{
                  color: data.expired ? "#2092c0" : "white"
                }}>
                {data.expired ? "Activate new key" : "Practice"}
              </Button>
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}>
              <Typography
                variant="subtitle2"
                color="green"
                fontWeight="bold"
                fontSize="13px">
                {!data.expired && (
                  `Valid until ${formatDate(data.expirationDate)}`
                )}
              </Typography>
              <Typography
                variant="button"
                display="block"
                color={data.expired ? "error" : "green"}
                textAlign="right">
                {data.expired ? "Expired" : "Active"}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}
