export default function WPLab() {
  return (
    <svg
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0"
      y="0"
      viewBox="0 0 512 512"
      className="">
      <g>
        <path
          d="M504.5 60H452V7.5a7.5 7.5 0 0 0-7.5-7.5H7.5A7.5 7.5 0 0 0 0 7.5v437a7.5 7.5 0 0 0 7.5 7.5H60v52.5a7.5 7.5 0 0 0 7.5 7.5h437a7.5 7.5 0 0 0 7.5-7.5v-437a7.5 7.5 0 0 0-7.5-7.5ZM497 75v45h-45V75Zm-60-60v45H15V15ZM15 75h422v362H15Zm60 422v-45h369.5a7.5 7.5 0 0 0 7.5-7.5V135h45v362Z"
          fill="#002755" opacity="1" data-original="#002755" className="">
        </path>
        <path
          d="M287.5 185h67a27.5 27.5 0 0 0 0-55h-67a27.5 27.5 0 0 0 0 55Zm0-40h67a12.5 12.5 0 0 1 0 25h-67a12.5 12.5 0 0 1 0-25ZM404.5 319.59H177.81a7.5 7.5 0 0 0-7.5 7.5v77.41a7.5 7.5 0 0 0 7.5 7.5H404.5a7.5 7.5 0 0 0 7.5-7.5v-77.41a7.5 7.5 0 0 0-7.5-7.5ZM397 397H185.31v-62.41H397ZM137.5 319.59h-90a7.5 7.5 0 0 0-7.5 7.5v77.41a7.5 7.5 0 0 0 7.5 7.5h90a7.5 7.5 0 0 0 7.5-7.5v-77.41a7.5 7.5 0 0 0-7.5-7.5ZM130 397H55v-62.41h75ZM67.5 295h140a27.54 27.54 0 0 0 27.5-27.5v-140a27.54 27.54 0 0 0-27.5-27.5h-140A27.54 27.54 0 0 0 40 127.5v140A27.54 27.54 0 0 0 67.5 295Zm0-15a12.22 12.22 0 0 1-3.11-.41l40.38-46.15 24.36 27.83a7.5 7.5 0 0 0 11.79-.64l29.31-41.87L212.39 279a12.42 12.42 0 0 1-4.89 1ZM55 127.5A12.52 12.52 0 0 1 67.5 115h140a12.52 12.52 0 0 1 12.5 12.5v136.21l-43.63-62.33a7.5 7.5 0 0 0-12.29 0l-30 42.81-23.7-27.08a7.5 7.5 0 0 0-11.29 0L55 267.54v-140Z"
          fill="#002755" opacity="1" data-original="#002755" className="">
        </path>
        <path
          d="M102.5 195A32.5 32.5 0 1 0 70 162.5a32.54 32.54 0 0 0 32.5 32.5Zm0-50A17.5 17.5 0 1 1 85 162.5a17.52 17.52 0 0 1 17.5-17.5ZM349.5 45h15a7.5 7.5 0 0 0 0-15h-15a7.5 7.5 0 0 0 0 15ZM399.5 45h15a7.5 7.5 0 0 0 0-15h-15a7.5 7.5 0 0 0 0 15ZM385.64 240.25l18.85-8.08a7.5 7.5 0 0 0-1.27-14.2l-77-17.78a7.51 7.51 0 0 0-9 9l17.78 77a7.5 7.5 0 0 0 14.2 1.27l8.08-18.85 24.17 24.16a7.5 7.5 0 0 0 10.61 0L409.8 275a7.5 7.5 0 0 0 0-10.61Zm1.08 36.64L360 250.2a7.52 7.52 0 0 0-5.31-2.2 6.91 6.91 0 0 0-1.42.14 7.49 7.49 0 0 0-5.47 4.41l-3.41 7.94-9.91-43 43 9.91-7.94 3.41A7.5 7.5 0 0 0 367.2 243l26.69 26.69ZM80 373.29h25a7.5 7.5 0 0 0 0-15H80a7.5 7.5 0 0 0 0 15ZM218.66 373.29h25a7.5 7.5 0 1 0 0-15h-25a7.5 7.5 0 0 0 0 15ZM278.66 373.29h25a7.5 7.5 0 0 0 0-15h-25a7.5 7.5 0 0 0 0 15ZM338.66 373.29h25a7.5 7.5 0 0 0 0-15h-25a7.5 7.5 0 0 0 0 15ZM394.5 467h-25a7.5 7.5 0 0 0 0 15h25a7.5 7.5 0 0 0 0-15ZM464.5 467h-25a7.5 7.5 0 0 0 0 15h25a7.5 7.5 0 0 0 0-15Z"
          fill="#002755" opacity="1" data-original="#002755" className="">
        </path>
      </g>
    </svg>
  )
}
