import {useMutation} from "@apollo/client"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import {
  Box,
  Button,
  FormControl,
  IconButton,
  Link,
  MenuItem,
  TextField,
  Theme,
  Typography
} from "@mui/material"
import {makeStyles} from "@mui/styles"
import CHECK_BATCH_IMPORT_USERS from "api/apollo/mutations/CHECK_BATCH_IMPORT_USERS"
import EXECUTE_BATCH_IMPORT_USERS from "api/apollo/mutations/EXECUTE_BATCH_IMPORT_USERS"
import CheckStudentsResult from "components/AddStudentsForm/components/CheckStudentsResult"
import CustomModal from "components/CustomModal"
import {
  CheckBatchImportUsersMutation,
  CheckBatchImportUsersMutationVariables,
  Classroom,
  ExecuteBatchImportUsersMutation,
  ExecuteBatchImportUsersMutationVariables,
  UserInput
} from "generated/graphql"
import {fetchSchoolStudentsThunk} from "store/slices/schoolSlice/schoolSlice"
import {checkEmail} from "utils/validation"
import DownloadCSV from "./components/DownloadCSV"
import {INewStudent} from "components/ManageStudentsTable"
import React, {useState} from "react"
import {useCSVReader} from "react-papaparse"
import {Link as RouterLink} from "react-router-dom"
import {useDispatch, useSelector} from "store"
import CustomErrorClass from "store/slices/notifier/customErrorClass"
import {customErrors} from "store/slices/notifier/errorObject"
import {handleError, notifyUser} from "store/slices/notifier/notifier"

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    minWidth: "500px",
    [theme.breakpoints.down(undefined)]: {
      width: "95%",
      minWidth: "0"
    }
  },
  fileInput: {
    height: "30px",
    padding: "10px",
    background: "white",
    border: "none",
    fontSize: "13px",
    color: "#172b4d"
  },
  fileButton: {
    height: "30px",
    background: "#eaeaea",
    border: "none",
    padding: "5px 12px",
    fontWeight: "600",
    borderRadius: "3px",
    cursor: "pointer",
    fontSize: "15px"
  }
}))

interface Props {
  classroom?: DeepPartial<Classroom>
  classrooms?: DeepPartial<Classroom>[]
  onAfterSubmit: () => void
  backHandler?: () => void
}

export type CheckResult = {
  id?: number
  email: string
  status: string | string[]
  suggestedAction: string | string[]
}

export default function AddMultipleStudentsForm({
  classroom,
  classrooms,
  onAfterSubmit,
  backHandler
}: Props) {
  const s = useStyles()

  const dispatch = useDispatch()

  const {CSVReader} = useCSVReader()

  const schoolId = useSelector((store) => store.schoolSlice.currentSchool)

  const [loading, setLoading] = useState(false)
  const [selectedClassroom, setSelectedClassroom] = useState(classroom?._id || classrooms[0]?._id || "")
  const [file, setFile] = useState<any[] | null>(null)
  const [checkStudentsModal, setCheckStudentsModal] = useState(false)
  const [checkStudentsResult, setCheckStudentsResult] = useState<{
    form: Omit<CheckBatchImportUsersMutationVariables, "users"> & {users: UserInput[]}
    checks: CheckResult[]
  } | null>(null)

  const [checkBatchImport, {loading: checkBatchImportLoading}] = useMutation<
    CheckBatchImportUsersMutation,
    CheckBatchImportUsersMutationVariables
  >(CHECK_BATCH_IMPORT_USERS)

  const [executeBatchImport, {loading: executeBatchImportLoading}] = useMutation<
    ExecuteBatchImportUsersMutation,
    ExecuteBatchImportUsersMutationVariables
  >(EXECUTE_BATCH_IMPORT_USERS)

  const handleSubmit = () => {
    if (!file?.length) {
      dispatch(handleError(new CustomErrorClass(customErrors.CANT_UPLOAD_FILE)))
    } else {
      const localErrors: Array<{
        id: number
        email: string
        errors: Array<{
          status: string
          suggestedAction: string
        }>
      }> = []

      const students = file?.reduce((acc, i, num) => {
        const data: INewStudent = {
          email: `${i["Email"] || ""}`.toLowerCase(),
          firstName: `${i["First Name"] || ""}`,
          lastName: `${i["Last Name"] || ""}`,
          password: `${i["Password"] || ""}`,
          studentId: `${i["Student ID"] || ""}`
        }

        const errList = []

        if (!data) {
          errList.push({
            id: num + 1,
            email: data?.email || "",
            status: "empty",
            suggestedAction: "fill"
          })
        } else {
          if (!data.studentId) {
            errList.push({
              id: num + 1,
              email: data?.email || "",
              status: "no_id",
              suggestedAction: "fill_id"
            })
          }

          if (!data.firstName) {
            errList.push({
              id: num + 1,
              email: data?.email || "",
              status: "no_first_name",
              suggestedAction: "fill_first_name"
            })
          }

          if (checkEmail(data.email)) {
            errList.push({
              id: num + 1,
              email: data?.email || "",
              status: "invalid_email",
              suggestedAction: "enter_valid_email"
            })
          }

          if (!data.lastName) {
            errList.push({
              id: num + 1,
              email: data?.email || "",
              status: "no_last_name",
              suggestedAction: "fill_last_name"
            })
          }

          if (!data.password) {
            errList.push({
              id: num + 1,
              email: data?.email || "",
              status: "no_password",
              suggestedAction: "fill_password"
            })
          } else if (data.password.length < 6) {
            errList.push({
              id: num + 1,
              email: data?.email || "",
              status: "short_password",
              suggestedAction: "change_password"
            })
          }
        }

        localErrors.push({
          id: num + 1,
          email: data?.email || "",
          errors: errList
        })

        acc.push(data)

        return acc
      }, [])

      if (!students?.length) {
        dispatch(handleError(new CustomErrorClass(customErrors.CANT_UPLOAD_FILE)))
      } else {
        const input: typeof checkStudentsResult["form"] = {
          schoolId,
          classroomId: selectedClassroom || undefined,
          users: students
        }

        let checkResults: CheckResult[] = []

        if (localErrors.length) {
          checkResults.push(...localErrors.map(i => ({
            id: i.id,
            email: i.email,
            status: i.errors.map(err => err.status),
            suggestedAction: i.errors.map(err => err.suggestedAction)
          })))
        }

        checkBatchImport({
          variables: input
        }).then(res => {
          const checks = res.data.checkBatchImportUsers.checks
          checkResults = checkResults.map((i) => {
            const checkFromRes = checks.find((item) => item.email === i.email.toLowerCase())

            if (!i.status.length) {
              i.status = checkFromRes.status
              i.suggestedAction = checkFromRes.suggestedAction
            } else if (checkFromRes.status !== "ok_for_import") {
              typeof i.status === "object" ? i.status.push(checkFromRes.status) : i.status = checkFromRes.status
              typeof i.suggestedAction === "object" ? (
                i.suggestedAction.push(checkFromRes.suggestedAction)
              ) : i.suggestedAction = checkFromRes.suggestedAction
            }

            return i
          })

          setCheckStudentsResult({
            form: input,
            checks: checkResults
          })
          setCheckStudentsModal(true)
        }).catch(err => {
          dispatch(handleError(err))
        })
      }
    }
  }

  const handleExecute = () => {
    setLoading(true)

    const filteredResult = checkStudentsResult.form.users

    if (filteredResult.length > 0) {
      executeBatchImport({
        variables: {
          schoolId: checkStudentsResult.form.schoolId,
          classroomId: checkStudentsResult.form.classroomId,
          users: filteredResult
        }
      }).then(() => {
        dispatch(fetchSchoolStudentsThunk(schoolId))
        dispatch(notifyUser({
          message: "Your CSV file was uploaded successfully! All records have been processed without any errors. You may proceed with the next steps. If you need further assistance, feel free to contact support.",
          variant: "success"
        }))
        onAfterSubmit()
      }).catch(err => {
        dispatch(handleError(err))
      }).finally(() => {
        handleCloseModal()
      })
    } else {
      handleCloseModal()
      onAfterSubmit()
    }
  }

  const handleCloseModal = () => {
    setCheckStudentsModal(false)
    setCheckStudentsResult(null)
    setLoading(false)
  }

  return (
    <Box className={s.form}>
      <CSVReader
        config={{header: true, skipEmptyLines: true}}
        onUploadAccepted={({data}) => {
          setFile(data)
        }}>
        {({getRootProps, acceptedFile}) => (
          <>
            <Box pt={3} px={4}>
              <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                <Box>
                  {!!backHandler && (
                    <IconButton color="secondary" onClick={backHandler}>
                      <ArrowBackIcon/>
                    </IconButton>
                  )}
                </Box>
                <Box px={2}>
                  <Typography variant="h5" textAlign="center">
                    Create Multiple Accounts With Bulk Upload
                  </Typography>
                </Box>
                <Box width={!!backHandler ? 40 : 0}/>
              </Box>
              {!classroom && (
                <>
                  <Typography mb={1}>
                    Select classroom
                  </Typography>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      select
                      label="Classroom"
                      name="classroom"
                      value={selectedClassroom}
                      onChange={e => {
                        setSelectedClassroom(e.target.value)
                      }}>
                      {classrooms.map(i => (
                        <MenuItem key={i._id} value={i._id}>
                          {i.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                  <Typography variant="body2" textAlign="end" p={1}>
                    <Link to="/classrooms" component={RouterLink} underline="always">
                      Create new classroom
                    </Link>
                  </Typography>
                </>
              )}
              <Box>
                <Typography mb={1}>
                  Upload CSV file for bulk upload
                </Typography>
                <Typography mb={1}>
                  Download a <DownloadCSV label="sample CSV template"/> to see
                  an example of the format required.
                </Typography>
                <Box display="grid" gridTemplateColumns="auto 1fr">
                  <Button className={s.fileButton} {...getRootProps()}>
                    Choose file
                  </Button>
                  <input
                    className={s.fileInput}
                    value={acceptedFile?.name || "No file chosen"}
                    disabled
                  />
                </Box>
              </Box>
            </Box>
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                variant="contained"
                sx={{px: 4}}
                disabled={!acceptedFile || !selectedClassroom || checkBatchImportLoading || executeBatchImportLoading}
                onClick={handleSubmit}>
                Submit
              </Button>
            </Box>
          </>
        )}
      </CSVReader>
      <CustomModal
        open={checkStudentsModal}
        onClose={handleCloseModal}>
        <CheckStudentsResult
          loading={loading}
          checks={checkStudentsResult?.checks}
          onSubmit={handleExecute}
          onClose={handleCloseModal}
        />
      </CustomModal>
    </Box>
  )
}
