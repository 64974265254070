import {useLazyQuery, useMutation} from "@apollo/client"
import {Box, Button} from "@mui/material"
import REMOVE_STUDENT_FROM_CLASSROOM from "api/apollo/mutations/REMOVE_STUDENT_FROM_CLASSROOM"
import GET_USER_ENROLLMENTS from "api/apollo/queries/GET_USER_ENROLLMENTS"
import ConfirmationAlert from "components/ConfirmationAlert"
import CustomModal from "components/CustomModal"
import DataTable from "components/DataTable"
import {
  DataTableActionButtons,
  DataTableQueryUpdate,
  DataTableSchema,
  DataTableState
} from "components/DataTable/types.t"
import TransferStudentForm from "components/TransferStudentForm"
import {
  Classroom,
  GetUserEnrollmentsQuery,
  GetUserEnrollmentsQueryVariables,
  RemoveStudentFromClassroomMutation,
  RemoveStudentFromClassroomMutationVariables,
  User
} from "generated/graphql"
import {Roles} from "types/access"
import AddUserToClassroomForm from "./components/AddUserToClassroomForm"
import React, {useMemo, useState} from "react"
import {useDispatch, useSelector} from "store"
import {handleError} from "store/slices/notifier/notifier"

interface Props {
  userData: Partial<User>
  isAdminView?: boolean
  districtId?: string
}

type ModalTypes = "ADD_USER_TO_CLASSROOM" | "REMOVE" | "TRANSFER"

export default function EnrollmentsTable({
  userData,
  isAdminView,
  districtId
}: Props) {
  const [query, setQuery] = useState<DataTableState | null>(null)
  const [modal, setModal] = useState<ModalTypes | null>(null)
  const [classroomToManage, setClassroomToManage] = useState<Classroom | null>(null)
  const [userEnrollmentsData, setUserEnrollmentsData] = useState<DeepPartial<Classroom>[] | null>(null)
  const user = useSelector(store => store.userSlice)

  const dispatch = useDispatch()

  const [userEnrollmentsQueryFetch, userEnrollmentsQuery] = useLazyQuery<
    GetUserEnrollmentsQuery,
    GetUserEnrollmentsQueryVariables
  >(GET_USER_ENROLLMENTS)

  const [removeStudentFromClassroom] = useMutation<
    RemoveStudentFromClassroomMutation,
    RemoveStudentFromClassroomMutationVariables
  >(REMOVE_STUDENT_FROM_CLASSROOM)

  const classroomIds = useMemo(() => {
    return userEnrollmentsQuery.data?.getUser?.classroomIds || null
  }, [userEnrollmentsQuery.data])

  const tableSchema: DataTableSchema<Classroom> = useMemo(() => {
    return [
      {
        type: "text",
        headerText: "District Name",
        headerNoWrap: true,
        contentWrap: "nowrap",
        fieldName: "district.name"
      },
      {
        type: "text",
        headerText: "School Name",
        headerNoWrap: true,
        contentWrap: "nowrap",
        fieldName: "school.name"
      },
      {
        type: "text",
        headerText: "Classroom Name",
        headerNoWrap: true,
        contentWrap: "nowrap",
        fieldName: "name"
      },
      {
        type: "custom",
        headerText: "Teacher Name",
        content: data => {
          return (
            <Box whiteSpace="pre-line">
              {data.teachers.length ? (
                data.teachers.map(i => (
                  <Box key={i._id} whiteSpace="nowrap">
                    {i.fullName}
                  </Box>
                ))
              ) : (
                ""
              )}
            </Box>
          )
        }
      },
      {
        type: "custom",
        headerText: "",
        headerNoWrap: true,
        content: data => {
          const transferButton = () => (
            !isAdminView && (
              <Button
                variant="outlined"
                color="success"
                size="small"
                disabled={districtId && (districtId !== data.districtId)}
                onClick={() => {
                  setClassroomToManage(data)
                  setModal("TRANSFER")
                }}>
                Transfer
              </Button>
            )
          )

          return (
            <Box>
              <Box display="flex" flexDirection="column" gap={1}>
                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  disabled={districtId && (districtId !== data?.districtId)}
                  onClick={() => {
                    setClassroomToManage(data)
                    setModal("REMOVE")
                  }}
                >
                  {isAdminView ? "Remove" : "Unenroll"}
                </Button>
                {transferButton()}
              </Box>
            </Box>
          )
        }
      }
    ]
  }, [userEnrollmentsData, isAdminView])

  const tableActionButtons: DataTableActionButtons = useMemo(() => {
    return [
      {
        key: "enroll",
        label: "Enroll",
        variant: "outlined",
        color: "success",
        disabled: districtId && userEnrollmentsData?.some(i => i.districtId !== districtId),
        onClick: () => setModal("ADD_USER_TO_CLASSROOM")
      }
    ]
  }, [districtId, userEnrollmentsData])

  const handleQuery: DataTableQueryUpdate= (state) => {
    setQuery(state)

    userEnrollmentsQueryFetch({
      fetchPolicy: "network-only",
      variables: {
        userId: userData._id
      }
    }).then(res => {
      let classrooms = res.data?.getUser?.classrooms || null
      if (![Roles.Admin, Roles.District_Admin, Roles.School_Admin].some(role => user.roles.includes(role))) {
        classrooms = classrooms?.filter(i => i.assignedTeachers.includes(user.id))
      }
      setUserEnrollmentsData(classrooms)
    })
  }

  const handleCancelModal = (refetch?: boolean) => {
    setClassroomToManage(null)
    setModal(null)
    refetch && handleQuery(query)
  }

  const handleRemove = () => {
    removeStudentFromClassroom({
      variables: {
        userId: userData._id,
        classroomId: classroomToManage._id
      }
    }).then(() => {
      handleQuery(query)
    }).catch(err => {
      dispatch(handleError(err))
    })
  }

  return (
   <Box minHeight="40vh">
     <DataTable
       schema={tableSchema}
       data={userEnrollmentsData}
       loading={userEnrollmentsQuery.loading}
       error={!!userEnrollmentsQuery.error}
       itemsTotalCount={userEnrollmentsData?.length}
       onQueryUpdate={handleQuery}
       actionButtons={tableActionButtons}
       search={{disabled: true}}
     />
     <CustomModal
       open={modal === "ADD_USER_TO_CLASSROOM"}
       onClose={() => handleCancelModal()}>
       <AddUserToClassroomForm
         user={userData}
         school={userEnrollmentsData && userEnrollmentsData[0]?.school}
         classroomIds={classroomIds}
         onAfterSubmit={() => handleCancelModal(true)}
       />
     </CustomModal>
     <CustomModal
       open={modal === "TRANSFER"}
       onClose={() => handleCancelModal()}>
       <TransferStudentForm
         classroomId={classroomToManage?._id}
         student={userData}
         onAfterSubmit={() => handleCancelModal(true)}
         onCancel={() => handleCancelModal()}
         schoolId={classroomToManage?.school?._id}
         districtId={districtId}
       />
     </CustomModal>
     <ConfirmationAlert
       isOpen={modal === "REMOVE"}
       setOpen={() => handleCancelModal()}
       handleConfirm={() => handleRemove()}
       handleCancel={() => handleCancelModal()}
       dialogTitle={isAdminView ?
          "Are you sure you want to remove the user from the classroom?"
         :
          `Are you sure you want to unenroll ${userData.fullName}?`
     }
       cancelText="No"
       confirmText={
        {
         color: "error",
         text: isAdminView ? "Remove" : "Yes, unenroll"
        }
       }
     />
   </Box>
  )
}
