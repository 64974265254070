import {gql} from "@apollo/client"

export default gql`
  query GetDisabilityMultiplierRequests(
    $status: [DisabilityMultiplierRequestStatus] 
    $districtId: GraphQLObjectId 
    $schoolId: GraphQLObjectId 
    $targetUserId: GraphQLObjectId
    $usersFromClassroomId: GraphQLObjectId
    $search: String
    $sortBy: String
    $order: SortOrder
    $offset: Int = 0
    $take: Int = 100
  ) {
    disabilityMultiplierRequests(
      status: $status 
      districtId: $districtId 
      schoolId: $schoolId 
      targetUserId: $targetUserId
      usersFromClassroomId: $usersFromClassroomId
      search: $search 
      sortBy: $sortBy 
      order: $order
      offset: $offset 
      take: $take
    ) {
      hasMore
      total
      items {
        _id
        decisionAt
        decisionBy
        disabilityMultiplierExpiration
        createdAt
        createdBy
        createdByUser {
          _id
          fullName
          totalTimeSpentInLabs
        }
        district {
          _id
          name
          remainingLicenseSeats {
            coursesRemainTotal
            examsRemainTotal
            labsRemainTotal
            practicesRemainTotal
          }
        }
        school {
          _id
          name
          remainingLicenseSeats {
            coursesRemainTotal
            examsRemainTotal
            labsRemainTotal
            practicesRemainTotal
          }
        }
        message
        multiplierValue
        proofFile
        proofFileKey
        proofFileMetadata {
          encoding
          filename
          mimetype
        }
        rejectionReason
        targetUser {
          _id
          lastName
          firstName
          fullName
          totalTimeSpentInLabs
        }
        status
      }
    }
  }
`
