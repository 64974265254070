import Requests from "pages/MyClassrooms/components/Requests"
import React from "react"
import StudentVouchersList from "components/StudentVouchersList"
import GreetingSection from "./components/GreetingSection"
import {Box, Container} from "@mui/material"
import useSettings from "hooks/useSettings"
import {Helmet} from "react-helmet-async"

export default function Index() {
  const {settings} = useSettings()

  return (
    <>
      <Helmet>
        <title>
          Overview | KP Platform
        </title>
      </Helmet>
      <Box pt={3} pb={8} minHeight="100%">
        <Container maxWidth={settings.compact ? "xl" : false}>
          <GreetingSection/>
          <Box mt={3}>
            <Requests innerTitle/>
          </Box>
          <Box mt={3}>
            <StudentVouchersList/>
          </Box>
        </Container>
      </Box>
    </>
  )
}
