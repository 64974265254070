import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow, Typography
} from "@mui/material"
import {CheckResult} from "components/AddStudentsForm/AddMultipleStudentsForm"
import {kpSecondaryColors} from "consts"
import {UserImportCheckStatus, UserImportSuggestedAction} from "generated/graphql"
import React, {useMemo} from "react"
import {isArray} from "lodash"
import {LoadingButton} from "@mui/lab"

interface Props {
  checks: CheckResult[]
  onSubmit: () => void
  onClose: () => void
  loading: boolean
}

const statuses = {
  "data_valid": {
    title: "",
    color: "success"
  },
  "empty": {
    title: "Empty Data",
    color: "error"
  },
  "no_id": {
    title: "No Student ID",
    color: "error"
  },
  "invalid_email": {
    title: "Invalid Email",
    color: "error"
  },
  "no_first_name": {
    title: "No First Name",
    color: "error"
  },
  "no_last_name": {
    title: "No Last Name",
    color: "error"
  },
  "no_password": {
    title: "No Password",
    color: "error"
  },
  "short_password": {
    title: "Invalid Password",
    color: "error"
  },
  [UserImportCheckStatus.AlreadyThere]: {
    title: "Already enrolled",
    color: kpSecondaryColors.orange
  },
  [UserImportCheckStatus.NotInvited]: {
    title: "Not invited",
    color: kpSecondaryColors.orange
  },
  [UserImportCheckStatus.DifferentSchool]: {
    title: "Different School",
    color: kpSecondaryColors.orange
  },
  [UserImportCheckStatus.OkForImport]: {
    title: "Ok for Import",
    color: "green"
  },
  [UserImportCheckStatus.UnconfirmedInCognito]: {
    title: "Unconfirmed in Cognito",
    color: "error"
  },
  [UserImportCheckStatus.OtherProblem]: {
    title: "Other problem",
    color: "error"
  }
}

const suggestions = {
  [UserImportSuggestedAction.Create]: "Create",
  [UserImportSuggestedAction.ConfirmAndInvite]: "Confirm and Invite",
  [UserImportSuggestedAction.Invite]: "Invite",
  [UserImportSuggestedAction.Skip]: "Skip",
  "fill": "Fill student data",
  "fill_id": "Enter Student ID",
  "enter_valid_email": "Enter Valid Email",
  "fill_first_name": "Enter First Name",
  "fill_last_name": "Enter Last Name",
  "fill_password": "Enter password",
  "change_password": "Change password"
}

export default function CheckStudentsResult({
  checks,
  onSubmit,
  onClose,
  loading
}: Props) {
  const containsError = useMemo(() => {
    return checks?.some(i => isArray(i.status)) || false
  }, [checks])

  return (
    <Box p={3}>
      {containsError && (
        <Box mb={2}>
          <Typography variant="h5" textAlign="center" sx={{mb: 2}}>
            CSV Upload Status
          </Typography>
          <Typography variant="body1">
            We encountered some issues with your CSV file.<br/>
            To resolve this, make the necessary corrections to the incorrect records in your CSV file and upload it again.<br/>
            If you continue to experience issues, feel free to contact support for further assistance.
          </Typography>
        </Box>
      )}
      <Table aria-label="caption-table">
        <TableHead>
          <TableRow>
            <TableCell sx={{minWidth: 85, fontSize: 20}}>
              #
            </TableCell>
            <TableCell align="left">
              Email
            </TableCell>
            <TableCell align="left">
              Status
            </TableCell>
            <TableCell align="left">
              Suggested action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(checks || []).map((row, num) => {
            const statusList = typeof row.status === "string" ? [row.status] : row.status
            const suggestedActionList = typeof row.suggestedAction === "string" ? (
              [row.suggestedAction]
            ) : row.suggestedAction

            return (
              <TableRow key={`${row.email}-${num}`}>
                <TableCell scope="row">
                  {num + 1}
                </TableCell>
                <TableCell align="left">
                  {row.email}
                </TableCell>
                <TableCell align="left">
                  {statusList.map(i => (
                    <Typography
                      key={i}
                      variant="body2"
                      fontWeight="bold"
                      color={statuses[i]?.color || undefined}>
                      {statuses[i]?.title}
                    </Typography>
                  ))}
                </TableCell>
                <TableCell align="left">
                  {suggestedActionList.map(i => (
                    <Typography key={i} variant="body2" fontWeight="bold">
                      {suggestions[i]}
                    </Typography>
                  ))}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <Box display="flex" alignItems="center" justifyContent="flex-end" gap={2} mt={3}>
        <LoadingButton
          variant="contained"
          disabled={loading}
          loading={loading}
          onClick={() => containsError ? onClose() : onSubmit()}
        >
          {containsError ? "Close" : "Submit"}
        </LoadingButton>
      </Box>
    </Box>
  )
}
