import intlFormat from "date-fns/intlFormat"

interface formatOptions {
  year?: "numeric" | "2-digit"
  month?: "numeric" | "2-digit" | "narrow" | "short" | "long"
  day?: "numeric" | "2-digit"
  hour?: "numeric" | "2-digit"
  minute?: "numeric" | "2-digit"
}

export default function formatDate(dateString: string, format?: "full" | "full-en" | "timeOnly") {
  const options: formatOptions =
    format === "full" ? {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric"
    } : format === "full-en" ? {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "numeric"
    } : format === "timeOnly" ? {
      hour: "numeric",
      minute: "numeric"
    } : {
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    }

  if (!dateString) {
    return "-"
  }

  const date = intlFormat(new Date(dateString), options, format === "full-en" ? {locale: "en"} : undefined)

  return format === "full-en" ? date : date.replaceAll("/", ".")
}
