import {Box, Typography} from "@mui/material"
import {kpPrimaryColors, maxWidthPDF} from "consts"
import {Certificate} from "generated/graphql"
import useElementDimensions from "hooks/useElementDimensions"
import React, {useEffect, useMemo, useState} from "react"
import QRCode from "react-qr-code"
import Layer from "assets/pictures/certificateTemplateLayer.png"
import AIEELayer from "assets/pictures/aieeCertificateTemplateLayer.png"

export type CertificateType = Partial<Certificate> & {certificateId: string}

interface Props {
  data: CertificateType
  showAfterRender?: boolean
  fullHeight?: boolean
}

export default function CertificateTemplate({data, showAfterRender, fullHeight}: Props) {
  const {ref, dimensions} = useElementDimensions()
  const matches = useMemo(() => {
    return dimensions?.width === maxWidthPDF
  }, [dimensions?.width])
  const [rendered, setRendered] = useState(false)

  useEffect(() => {
    if (!rendered && showAfterRender) {
      if (dimensions?.width > 0) {
        setRendered(true)
      }
    }
  }, [dimensions])

  return (
    <Box
      maxWidth={maxWidthPDF}
      height={fullHeight ? "100%" : undefined}
      mx="auto"
      ref={ref}
      sx={{
        opacity: showAfterRender && !rendered ? 0 : 1,
        aspectRatio: 1000/707
      }}>
      <Box position="relative" height={fullHeight ? "100%" : undefined}>
        <img src={data.examCode === "aiee" ? AIEELayer : Layer} alt="layer" className="object-contain" fetchpriority="high"/>
        <Box sx={{
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          top: "30.5%",
          textAlign: "center",
          width: "100%"
        }}>
          {(matches || dimensions) &&
            <Typography
              sx={{
                width: "100%",
                fontSize: `${matches ? "3.8rem" : `calc(${dimensions?.width}px / 19.8)`} !important`
              }}
              variant="h1"
              className="EBGaramondFont"
              color={kpPrimaryColors.lightBlue}>
              {data.studentName}
            </Typography>
          }
        </Box>
        <Box sx={{
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          top: "52.5%",
          textAlign: "center",
          width: "100%"
        }}>
          {(matches || dimensions) &&
            <Typography
              sx={{
                fontSize: `${matches ? "2.5rem" : `calc(${dimensions?.width}px / 30)`} !important`
              }}
              variant="h1"
              className="NexaFont"
              color={kpPrimaryColors.darkBlue}>
              {data.courseName}
            </Typography>
          }
        </Box>
        <Box sx={{
          width: "90%",
          display: "flex",
          alignItems: "center",
          position: "absolute",
          left: "7%",
          bottom: "9.2%",
          textAlign: "center",
          gap: "2%"
        }}>
          <Box width={matches ? "5.36rem" : `calc(${dimensions?.width}px / 14)`} display="flex">
            <QRCode
              value={`${document.location.origin}/c/${data.certificateId}`}
              style={{height: "auto", maxWidth: "100%", width: "100%"}}
              fgColor="#002955"
            />
          </Box>
          <Box display="flex" flexDirection="column" alignItems="start">
            {(matches || dimensions) &&
              <>
                <Typography
                  sx={{
                    fontSize: `${matches ? "1.16668rem" : `calc(${dimensions?.width}px / 65.8)`} !important`
                  }}
                  variant="body1"
                  color={kpPrimaryColors.darkBlue}
                  className="CanvaSansFont">
                  {data.issuedAt}
                </Typography>
                <Typography
                  sx={{
                    fontSize: `${matches ? "1.16668rem" : `calc(${dimensions?.width}px / 65.8)`} !important`
                  }}
                  mt="2%"
                  variant="body1"
                  color={kpPrimaryColors.darkBlue}
                  className="CanvaSansFont">
                  Credential ID: {data.certificateId}
                </Typography>
              </>
            }
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

