import {useSelector} from "store"
import {useMemo} from "react"
import {Roles} from "types/access"
import {LINKS} from "consts/links"

export default function useMainPageLink(
  role?: "districtAdmin" | "schoolAdmin"
) {
  const {roles, fakeRoles} = useSelector((state) => state.userSlice)

  const {isStudent, mainPageLink, rolesLoading} = useMemo(() => {
    if (!roles?.length && !fakeRoles?.length)
      return {
        isStudent: true,
        isDistrict: false,
        mainPageLink: LINKS.main,
        rolesLoading: true
      }

    if (
      (roles?.includes(Roles.Student) ||
      fakeRoles?.includes(Roles.Student))
    ) {
      return {
        isStudent: true,
        isDistrict: false,
        mainPageLink: LINKS.dashboard,
        rolesLoading: false
      }
    }

    if (
      role === "districtAdmin" && (
      (
        roles?.includes(Roles.District_Admin) ||
        fakeRoles?.includes(Roles.District_Admin)
      ))
    ) {
      return {
        isStudent: false,
        isDistrict: true,
        mainPageLink: LINKS.districtAdminSchools,
        rolesLoading: false
      }
    }

    return {
      isStudent: false,
      isDistrict: false,
      mainPageLink: LINKS.classrooms,
      rolesLoading: false
    }
  }, [roles, fakeRoles])

  return {
    isStudent,
    mainPageLink,
    rolesLoading
  }
}
