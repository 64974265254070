import * as Auth from "aws-amplify/auth"
import apiCall from "api/rest"
import {ENDPOINT} from "consts/endpoints"

const authAsProctor = (proctorCredentials) => {
  return apiCall({
    url: ENDPOINT.authAsProctor,
    method: "POST",
    data: proctorCredentials
  })
}

export async function forgotPassword(email) {
  try {
    await Auth.forgotPassword(email)
    return true
  } catch (err) {
    console.log(err)
    return err
  }
}

export async function resetPassword(email, code, password) {
  try {
    await Auth.forgotPasswordSubmit(email, code, password)
    return true
  } catch (err) {
    console.log(err)
    return err
  }
}

/**
 * @deprecated This function is deprecated as of January 2024.
 * Email verification step has been removed from the authentication flow.
 */
export async function resendConfirmationCode(username) {
  console.warn(
    'resendConfirmationCode is deprecated. Email verification step has been removed from the authentication flow.'
  )
  
  try {
    const result = await Auth.resendSignUp(username)
    return {
      success: true,
      response: result
    }
  } catch (err) {
    return {
      success: false,
      response: err
    }
  }
}

const authService = {
  authAsProctor
}

export default authService
