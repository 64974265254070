import {useLazyQuery, useMutation} from "@apollo/client"
import ADD_STAFF_TO_SCHOOL from "api/apollo/mutations/ADD_STAFF_TO_SCHOOL"
import CHECK_BATCH_IMPORT_USERS from "api/apollo/mutations/CHECK_BATCH_IMPORT_USERS"
import EXECUTE_BATCH_IMPORT_USERS from "api/apollo/mutations/EXECUTE_BATCH_IMPORT_USERS"
import GET_DISTRICT_IDS from "api/apollo/queries/GET_DISTRICT_IDS"
import GET_SCHOOL_IDS from "api/apollo/queries/GET_SCHOOL_IDS"
import SearchIcon from "assets/icons/Search"
import CheckStudentsResult from "components/AddStudentsForm/components/CheckStudentsResult"
import CustomModal from "components/CustomModal"
import SelectAsync from "components/SelectAsync"
import {
  AddStaffToSchoolMutation,
  AddStaffToSchoolMutationVariables,
  BatchUsersImportCheckResult,
  CheckBatchImportUsersMutation,
  CheckBatchImportUsersMutationVariables,
  District,
  ExecuteBatchImportUsersMutation,
  ExecuteBatchImportUsersMutationVariables,
  GetDistrictIdsQuery,
  GetDistrictIdsQueryVariables,
  GetSchoolIdsQuery,
  GetSchoolIdsQueryVariables,
  School,
  SortOrder,
  User,
  UserInput
} from "generated/graphql"
import React, {useState} from "react"
import {Formik} from "formik"
import {useDispatch, useSelector} from "store"
import {handleError} from "store/slices/notifier/notifier"
import {Roles} from "types/access"
import * as Yup from "yup"
import {
  Box,
  Button,
  FormControlLabel,
  Switch,
  TextField,
  Theme,
  Typography
} from "@mui/material"
import {createStyles, makeStyles} from "@mui/styles"

const useStyles = makeStyles((theme: Theme) => createStyles({
  form: {
    minWidth: "500px",
    [theme.breakpoints.down(undefined)]: {
      width: "95%",
      minWidth: "0"
    }
  }
}))

const addInputItems = {
  fieldName: "email",
  label: "Email"
}

const schoolInputItem = {
  fieldName: "schoolId",
  label: "School",
  isSchoolInput: true
}

const districtInputItem = {
  fieldName: "districtId",
  label: "District",
  isDistrictInput: true
}

const createInputItems = [{
  fieldName: "firstName",
  label: "First Name"
}, {
  fieldName: "lastName",
  label: "Last Name"
}, {
  fieldName: "email",
  label: "Email"
}, {
  fieldName: "password",
  label: "Password"
}, {
  fieldName: "passwordConfirm",
  label: "Confirm password"
}]

interface InputItem {
  fieldName: string
  label: string
  isDistrictInput?: boolean
  isSchoolInput?: boolean
}

export interface AddStaffFormProps {
  type: "ADD" | "CREATE"
  schoolId?: string
  districtId?: string
  onCancel: () => void
  userEmail?: string
  onSubmit(
    type: "ADD" | "CREATE_CONFIRMATION" | "CREATE",
    isAdmin?: boolean,
    user?: Partial<User>
  ): void
}

export default function AddPersonnelForm({
  type,
  schoolId,
  districtId,
  onCancel,
  onSubmit,
  userEmail
}: AddStaffFormProps) {
  const dispatch = useDispatch()

  const s = useStyles()

  const user = useSelector((store) => store.userSlice)

  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState("")
  const [selectedSchoolId, setSelectedSchoolId] = useState("")
  const [isAdmin, setIsAdmin] = useState(false)
  const [schools, setSchools] = useState<School[]>([])
  const [districts, setDistricts] = useState<District[]>([])
  const [checkStudentsModal, setCheckStudentsModal] = useState(false)
  const [checkStudentsResult, setCheckStudentsResult] = useState<{
    form: Omit<CheckBatchImportUsersMutationVariables, "users"> & {users: UserInput[]}
    checks: BatchUsersImportCheckResult["checks"]
  } | null>(null)

  const [districtIdsQueryFetch, districtIdsQuery] = useLazyQuery<
    GetDistrictIdsQuery,
    GetDistrictIdsQueryVariables
  >(GET_DISTRICT_IDS)

  const [schoolIdsQueryFetch, schoolIdsQuery] = useLazyQuery<
    GetSchoolIdsQuery,
    GetSchoolIdsQueryVariables
  >(GET_SCHOOL_IDS)

  const [addStaffToSchool] = useMutation<
    AddStaffToSchoolMutation,
    AddStaffToSchoolMutationVariables
  >(ADD_STAFF_TO_SCHOOL)

  const [checkBatchImport, {loading: checkBatchImportLoading}] = useMutation<
    CheckBatchImportUsersMutation,
    CheckBatchImportUsersMutationVariables
  >(CHECK_BATCH_IMPORT_USERS)

  const [executeBatchImport, {loading: executeBatchImportLoading}] = useMutation<
    ExecuteBatchImportUsersMutation,
    ExecuteBatchImportUsersMutationVariables
  >(EXECUTE_BATCH_IMPORT_USERS)

  const handleSubmit = ({
    passwordConfirm,
    ...values
  }: any) => {
    if (type === "ADD") {
      addStaffToSchool({
        variables: {
          schoolId: schoolId || values?.schoolId,
          userEmail: values.email.toLowerCase(),
          isTeacher: true,
          isAdmin
        }
      }).then(() => {
        onSubmit("ADD")
      }).catch(err => {
        if (userEmail) {
          dispatch(handleError(err))
        } else {
          onSubmit("CREATE_CONFIRMATION")
        }
      })

      setEmail(values.email.toLowerCase())
      setSelectedSchoolId(values?.schoolId || "")
    } else {
      const input: typeof checkStudentsResult["form"] = {
        schoolId: schoolId || selectedSchoolId,
        users: [
          {
            email: values.email.toLowerCase(),
            firstName: values.firstName,
            lastName: values.lastName,
            password: values.password
          }
        ]
      }

      checkBatchImport({
        variables: input
      }).then(res => {
        setCheckStudentsResult({
          form: input,
          checks: res.data.checkBatchImportUsers.checks
        })
        setCheckStudentsModal(true)
      }).catch(err => {
        dispatch(handleError(err))
      })
    }
  }

  const handleExecute = () => {
    setLoading(true)

    const user = checkStudentsResult.form.users[0] || null

    if (user) {
      executeBatchImport({
        variables: {
          schoolId: checkStudentsResult.form.schoolId,
          classroomId: checkStudentsResult.form.classroomId,
          users: [user]
        }
      }).then(() => {
        setCheckStudentsResult(null)

        return addStaffToSchool({
          variables: {
            schoolId: checkStudentsResult.form.schoolId,
            userEmail: user.email,
            isTeacher: true,
            isAdmin
          }
        })
      }).then(() => {
        onSubmit("CREATE", isAdmin, user)
      }).catch(err => {
        dispatch(handleError(err))
      }).finally(() => {
        handleClose()
      })
    } else {
      handleClose()
    }
  }

  const handleQuerySchools = (
    value: string,
    selectedDistrictId?: string
  ) => {
    schoolIdsQueryFetch({
      variables: {
        search: value,
        districtIds: districtId ? [districtId] : selectedDistrictId ? [selectedDistrictId] : undefined,
        sortBy: "name",
        order: SortOrder.Asc
      }
    }).then(res => {
      setSchools(res.data?.schools?.items || [])
    })
  }

  const handleQueryDistricts = (value: string) => {
    districtIdsQueryFetch({
      variables: {
        search: value
      }
    }).then(res => {
      setDistricts(res.data?.districts?.items || [])
    })
  }

  const handleClose = () => {
    setLoading(false)
    setCheckStudentsModal(false)
    setCheckStudentsResult(null)
  }

  return (
    <Formik
      enableReinitialize
      key={type}
      initialValues={type === "ADD" ? {
        email: userEmail || "",
        schoolId: "",
        districtId: ""
      } : {
        firstName: "",
        lastName: "",
        email: email || "",
        password: ""
      }}
      validationSchema={type === "ADD" ? Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        schoolId: !schoolId && Yup.string()
          .required("School is required"),
        districtId: !districtId && !schoolId && Yup.string()
      }) : Yup.object().shape({
        firstName: Yup.string().required("First Name is required"),
        lastName: Yup.string().required("Last Name is required"),
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        password: Yup.string()
          .min(7, "Must be at least 7 characters")
          .max(255)
          .required("Required"),
        passwordConfirm: Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required("Required")
      })}
      onSubmit={handleSubmit}>
      {({
        errors,
        values,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        isValid,
        dirty,
        setFieldValue
      }): JSX.Element => {
        return (
          <form className={s.form} onSubmit={handleSubmit}>
            <Box py={3} px={3} minWidth="35vw">
              <Box>
                <Typography variant="h5" mb={3}>
                  Add New Personnel
                </Typography>
                <Box>
                  {(type === "ADD" ? [
                    !userEmail && addInputItems,
                    !schoolId && !districtId && districtInputItem,
                    !schoolId && schoolInputItem
                  ].filter(Boolean) : createInputItems).map(({
                    label,
                    fieldName,
                    isSchoolInput,
                    isDistrictInput
                  }: InputItem, num, list) => {
                    return (
                      <Box key={fieldName} mb={list.length > 1 ? 2 : 0}>
                        {isSchoolInput || isDistrictInput ? (
                          <SelectAsync
                            id={fieldName}
                            label={label}
                            icon={<SearchIcon/>}
                            error={Boolean(touched[fieldName] && errors[fieldName])}
                            touched={Boolean(touched[fieldName])}
                            value={values[fieldName]}
                            onChange={(value) => {
                              setFieldValue(fieldName, value)
                              if (isDistrictInput) {
                                setFieldValue("schoolId", "")
                                setSchools([])
                              }
                            }}
                            loading={isDistrictInput ? districtIdsQuery.loading : schoolIdsQuery.loading}
                            options={(isDistrictInput ? districts : schools).map(i => ({
                              label: i.name,
                              value: i._id
                            }))}
                            onUpdateOptions={value => {
                              isDistrictInput ? (
                                handleQueryDistricts(value)
                              ) : handleQuerySchools(value, values["districtId"])
                            }}
                          />
                        ) : (
                          <TextField
                            name={fieldName}
                            value={values[fieldName] || ""}
                            error={Boolean(touched[fieldName] && errors[fieldName])}
                            helperText={touched[fieldName] && (errors[fieldName] as string)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label={label}
                            type={["password", "passwordConfirm"].includes(fieldName) ? "password" : "text"}
                            fullWidth
                            variant="outlined"
                          />
                        )}
                      </Box>
                    )
                  })}
                </Box>
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center" mt={4}>
                {(user.roles.includes(Roles.Admin) || user.roles.includes(Roles.School_Admin)) &&
                  <Box pr={2}>
                    <FormControlLabel
                      label="Assign Administrator Access"
                      labelPlacement="start"
                      sx={{ml: 0}}
                      control={
                        <Switch
                          edge="end"
                          checked={isAdmin}
                          onChange={e => setIsAdmin(e.target.checked)}
                          inputProps={{"aria-label": "controlled"}}
                        />
                      }
                    />
                  </Box>
                }
                <Box display="flex" justifyContent="flex-end" gap={1} ml="auto">
                  <Button variant="outlined" onClick={onCancel}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={(!(isValid && dirty)) || checkBatchImportLoading || executeBatchImportLoading}>
                    Submit
                  </Button>
                </Box>
              </Box>
            </Box>
            <CustomModal
              open={checkStudentsModal}
              onClose={() => {
                setCheckStudentsModal(false)
                setCheckStudentsResult(null)
              }}>
              <CheckStudentsResult
                loading={loading}
                checks={checkStudentsResult?.checks}
                onSubmit={handleExecute}
                onClose={handleClose}
              />
            </CustomModal>
          </form>
        )
      }}
    </Formik>
  )
}
