import {useCallback, useEffect, useState} from "react"
import jsonParse from "utils/jsonParse"

export default function useLocalStorage<T>(key: string): [T | null, (value: T) => void, boolean]
export default function useLocalStorage(): boolean
export default function useLocalStorage<T>(key?: string): [T, (value: T) => void, boolean] | boolean {
  const [value, setValue] = useState<T>(jsonParse(localStorage.getItem(key)))
  const [hasToken, setHasToken] = useState<boolean>(tokenExists())

  useEffect(() => {
    const handler = (e: StorageEvent) => {
      if (key && e.key === key) {
        const item = localStorage.getItem(key)
        setValue(jsonParse(item ?? ""))
      }
      setHasToken(tokenExists())
    }

    window.addEventListener("storage", handler)
    return () => window.removeEventListener("storage", handler)
  }, [key])

  const setValueWrap = useCallback((newValue: T) => {
    setValue(newValue)
    if (key) {
      localStorage.setItem(key, JSON.stringify(newValue))
      window.dispatchEvent(new StorageEvent("storage", {key}))
    }
  }, [key])

  function tokenExists() {
    return Object.keys(localStorage).some(i => i.includes("idToken"))
  }

  useEffect(() => {
    setHasToken(tokenExists())
  }, [key, value])

  if (key === undefined) {
    return hasToken
  }

  return [value, setValueWrap, hasToken]
}
